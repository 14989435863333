.left-image-deco {
  position: absolute;
  left: -8%;
  bottom: 10%;
  max-width: 250px;
  transform: scale(1.2);
}
.right-image-deco {
  min-width: 30%;
  display: none;
}

.center-container-form {
  min-width: 40%;
  padding: 0 20px;
}

@media (min-width: 640px) {
  .left-image-deco {
  }
  .right-image-deco {
    display: none;
  }
}

@media (min-width: 1024px) {
  .left-image-deco {
    left: -2%;
  }
  .center-container-form {
    padding: 0;
  }
}

@media (min-width: 1280px) {
  .left-image-deco {
    position: static;
    min-width: 30%;
    max-width: none;
    z-index: 10;
  }
  .right-image-deco {
    display: block;
    position: static;
    min-width: 30%;
    max-width: none;
    z-index: 10;
  }
}

.custom-radio {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-radio-container {
  margin-left: 40px;
  margin-top: 10px;
  cursor: pointer;
}

.custom-radio::after {
  content: "";
  display: block;
  border: 1px solid #000000;
  background-color: #fff;
  width: 20px;
  height: 20px;
  margin-top: -12px;
  margin-left: -35px;
  transition: all 0.2s ease-in;
}

.custom-radio-image::after {
  margin-top: -10px;
  margin-left: -10px;
}

.custom-radio:checked::after {
  background-color: #90d3f0;
}

.loader {
  border: 3px solid #fff;
  border-radius: 100px;
  width: 100%;
  max-width: 400px;
  height: 30px;
  background-size: 100% 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-position: -400px;
}
