.pagination-block {
  display: flex;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  font-size: 20px;
  color: white;
  padding: 8px 25px;
}
.pagination-block > span {
  line-height: 20px;
}
.pagination-block > span:first-child {
  font-size: 30px;
  line-height: 30px;
}
